<template>
    <div>
        <ts-page-title
            :title="$t('loanRecovery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanRecovery.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanRecovery.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                :row-class-name="rowClassName"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="loan_number">
                    {{
                        row.loan_disbursement
                            ? row.loan_disbursement.loan_number
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="customer">
                    {{ row.customer ? row.customer.customer_name_en : "" }} |
                    {{ row.customer ? row.customer.customer_name_kh : "" }}
                </template>
                <template slot-scope="{ row }" slot="amount">
                    <strong class="tw-text-green-500">{{
                        formatCurrencyWithCode(
                            row.amount,
                            row.loan_disbursement.currency
                        )
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="co">
                    {{
                        row.loan_disbursement.co
                            ? row.loan_disbursement.co.employee_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="created_by">
                    {{
                        row.created_by.employee
                            ? row.created_by.employee.employee_name_en
                            : row.created_by.user_name
                    }}
                </template>
                <template slot-scope="{ row }" slot="modified_by">
                    {{
                        row.modified_by
                            ? row.modified_by.employee
                                ? row.modified_by.employee.employee_name_en
                                : row.modified_by.user_name
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="deleted_by">
                    {{
                        row.deleted_by
                            ? row.deleted_by.employee
                                ? row.deleted_by.employee.employee_name_en
                                : row.deleted_by.user_name
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="is_deleted">
                   <span
                        v-if="row.is_deleted"
                        class="badge bg-success tw-mr-1 tw-capitalize"
                    >
                    Yes
                    </span>
                    <span
                        v-else
                        class="badge bg-secondary tw-mr-1 tw-capitalize"
                    >
                    No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-show="!row.is_deleted"
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="!row._deleting"
                        v-show="!row.is_deleted"
                        @on-popper-show="() => (model.deleted_reason = '')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t("journalEntry.reason")
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first("deleted_reason") }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Drawer
                :title="$t('filter')"
                :closable="false"
                :value="visible"
                @on-close="() => (visible = false)"
                width="300px"
            >
                <div class="mb-3">
                    <label class="form-col-label control-label">{{
                        $t("branchName")
                    }}</label>
                    <ts-branch-filter @filter="value => (branch_id = value)" />
                </div>
            </Drawer>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('loanRecovery.pageTitle')"
                width="700"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters, mapState } from "vuex";
import FromAction from "./form";
import { required } from "vuelidate/lib/validators";
import { Errors } from "form-backend-validation";

export default {
    name: "loanRecoveryIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            visible: false,
            errors: new Errors(),
            branch_id: [],
            model: {
                deleted_reason: ""
            },
            width: "width: 300px"
        };
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ["model.deleted_reason"]
    },
    computed: {
        ...mapState("creditOperation/loanRecovery", [
            "resources",
            "pagination"
        ]),
        ...mapGetters(["formatCurrencyWithCode"]),
        reasonErrors() {
            const errors = [];
            if (!this.$v.model.deleted_reason.$error) return errors;
            !this.$v.model.deleted_reason.required && errors.push("Required");
            return errors;
        },
        search: {
            get() {
                return this.$store.state.creditOperation.loanRecovery.search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/loanRecovery/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/loanRecovery/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t("loanRecovery.branch"),
                    slot: "branch",
                    sortable: true,
                    minWidth: 100
                },
                {
                    title: this.$t("loanRecovery.recoveryDate"),
                    key: "recovery_date",
                    sortable: true,
                    align: "center",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.loanNumber"),
                    slot: "loan_number",
                    sortable: true,
                    align: "center",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.customerName"),
                    slot: "customer",
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("loanRecovery.amount"),
                    slot: "amount",
                    sortable: true,
                    align: "right",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.co"),
                    slot: "co",
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.remarks"),
                    key: "remarks",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.createdBy"),
                    slot: "created_by",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.createdDate"),
                    key: "created_date",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.modifiedBy"),
                    slot: "modified_by",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.modifiedDate"),
                    key: "modified_date",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.isDeleted"),
                    slot: "is_deleted",
                    fixed: "right",
                    minWidth: 130,
                    filters: [
                        {
                            label: "Yes",
                            value: true
                        },
                        {
                            label: "No",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                is_deleted: value
                            },
                            branch_id: this.branch_id,
                            search: this.search
                        });
                    }
                },
                {
                    title: this.$t("loanRecovery.deletedBy"),
                    slot: "deleted_by",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.deletedDate"),
                    key: "deleted_date",
                    minWidth: 150
                },
                {
                    title: this.$t("loanRecovery.reason"),
                    key: "deleted_reason",
                    minWidth: 150
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/loanRecovery/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/loanRecovery/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.clearInput();
            this.$store.commit(
                "creditOperation/loanRecovery/SET_EDIT_DATA",
                {}
            );
        },
        addNew() {
            this.$store.commit(
                "creditOperation/loanRecovery/SET_EDIT_DATA",
                {}
            );
            this.showForm = true;
            this.$refs.form_action.loadLoanDisbursement();
            this.$refs.form_action.clearInput();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN RECOVERY",
                desc: not.text
            });
        },
        async onDelete(record) {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;

            record._deleting = true;
            this.$store
                .dispatch("creditOperation/loanRecovery/destroy", {
                    id: record.transaction_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData();
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                    this.model.deleted_reason = "";
                });
        },
        rowClassName(row) {
            if (row.is_deleted) {
                return "demo-table-info-row";
            }
            return "";
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search, branch_id: this.branch_id });
        }, 500),
        branch_id: debounce(function(value) {
            this.fetchData({ branch_id: value });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/loanRecovery/RESET_STATE");
        next();
    }
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // }
};
</script>
<style>
.ivu-table .demo-table-info-row td {
    font-style: italic;
    text-decoration: line-through red;
}
</style>
